import React from 'react'
import axios from 'axios'
import { toast } from 'react-toastify'
import Videopopup from '../Videopopup';
import { Link } from 'react-router-dom';
import { useAtom } from 'jotai';
import { status } from '../Jotai'
import { Helmet } from "react-helmet";


function Articlewriter() {

  const [login, setLogin] = useAtom(status);
  var tempdata = '';

  const [loading, setLoading] = React.useState(false);
  const [output, setOutput] = React.useState('');
  const [btntxt, setBtntxt] = React.useState('Generate AI Content');
  const [isOpen, setIsOpen] = React.useState(false);
  const [inputt, setInputt] = React.useState({
    ttl: 'The Top 3 Exercise Mistakes to Avoid',
    kw: 'daily workout, push ups mistakes, exercise mistakes',
    engine: 'mango',
    writer: 'Article Writer',
    writingStyle: 'Formal',
    audience: 'General',
  })

  const Popup = props => {
    return (
      <div className="popup-box">
        <div className="box">
          <span className="close-icon" onClick={props.handleClose}>x</span>
          {props.content}
        </div>
      </div>
    );
  };

  const copyClipboard = () => {
    navigator.clipboard.writeText(output);
    toast.success('Text Copied to Clipboard!')
  }


  function togglePopup() {
    setIsOpen(!isOpen);
  }

  function useWindowSize() {
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [windowSize, setWindowSize] = React.useState({
      width: undefined,
      height: undefined,
    });
    React.useEffect(() => {
      // Handler to call on window resize
      function handleResize() {
        // Set window width/height to state
        setWindowSize({
          width: window.innerWidth,
          height: window.innerHeight,
        });
      }
      // Add event listener
      window.addEventListener("resize", handleResize);
      // Call handler right away so state gets updated with initial window size
      handleResize();
      // Remove event listener on cleanup
      return () => window.removeEventListener("resize", handleResize);
    }, []); // Empty array ensures that effect is only run on mount
    return windowSize;
  }

  async function process(e) {

    if (inputt.ttl === '') {
      return toast.error('Enter title first! For help watch demo video')
    } else if (inputt.kw === '') {
      return toast.error('Enter keywords! For help watch demo video')
    } else if (inputt.num === 0) {
      return toast.error('Unknown Error!')
    }


    if (localStorage.getItem('token') == null) {
      return toast.warning(<div>
        Please <Link style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }} to="/login">Login</Link> first. You can also <Link style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }} to="/signup">Create New Account</Link>!</div>)
    }

    setLoading(true)


    for (var i = 0; i < 1; i++) {
      setBtntxt(` Generating AI Content`)
      try {
        let response = await axios.post('https://api.aiwritergo.com/aiwriter', {
          ttl: inputt.ttl,
          kw: inputt.kw,
          engine: inputt.engine,
          writer: inputt.writer,
          writingStyle: inputt.writingStyle,
          audience: inputt.audience
        }, {
          headers: {
            Authorization: localStorage.getItem('token')
          },
          timeout: 60000
        })
        if (response.data.error == null) {
          tempdata = `${tempdata} \n------Output------\n ${response.data.msg}`
          setOutput(tempdata)
          // setOutput(...output,'\n ------Output (${i - 1 })------\n' + response.data.msg);
          // setOutput(`${output} \n ------Output (${i - 1 })------\n ${response.data.msg}`)
        } else {
          setLoading(false);
          setBtntxt('Generate AI Content')
          setOutput(tempdata)
          i = inputt.num + 1;
          return toast.error(response.data.error)
        }
        if (inputt.num === i) {
          setLoading(false);
          setBtntxt('Generate AI Content')
          setOutput(tempdata)
          return toast.success('AI Text generation completed!')
        }
      } catch (err) {
        console.log(inputt)
        console.log(err)
        setOutput(tempdata)
        setLoading(false);
        setBtntxt('Generate AI Content')
        console.log(err)
        i = inputt.num + 1;
        return toast.error('Something went wrong please contact support')
      }
    }
    setOutput(tempdata)
    tempdata = ''
    setLoading(false);
    toast.success('AI Content Generation Completed!')
    setBtntxt('Generate AI Content')
    const section = document.querySelector('#output-window');
    section.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }

  const [loading2, setLoading2] = React.useState(false)
  const [btntxt2, setBtntxt2] = React.useState('Write More Content')
  const [writemore, setWritemore] = React.useState('')


  function process2(e) {
    if (localStorage.getItem('token') == null) {
      return toast.warning(<div>
        Please <Link style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }} to="/login">Login</Link> first. You can also <Link style={{ color: 'blue', fontWeight: 'bold', textDecoration: 'underline' }} to="/signup">Create New Account</Link>!</div>)
    }
    setLoading2(true)
    setBtntxt2(` Writing More Content`)
    axios.post('https://api.aiwritergo.com/aiwriter', {
      ttl: inputt.ttl,
      kw: `${writemore}`,
      engine: inputt.engine,
      writer: inputt.writer,
      writingStyle: inputt.writingStyle,
      audience: inputt.audience
    }, {
      headers: {
        Authorization: localStorage.getItem('token')
      },
      timeout: 60000
    }).then((resp) => {
      if (resp.data.error == null) {
        const tempdata2 = `${output} \n ${resp.data.msg}`
        setOutput(tempdata2)
        setBtntxt2(' Write More Content')
        setLoading2(false);
        return toast.success('More content Generated!')
      } else {
        setLoading2(false);
        setBtntxt2(' Write More Content')
        setOutput(tempdata)
        return toast.error(resp.data.error)
      }
    }).catch((err) => {
      console.log(inputt)
      setOutput(tempdata)
      setLoading2(false);
      setBtntxt2('Generate AI Content')
      console.log(err)
      return toast.error('Something went wrong please contact support')
    })


  }


if(window.innerWidth > 700){
  return (
    <>
    <Helmet>
        <meta charSet="utf-8" />
        <title>AI Writer Go - AI Article Writer Tool</title>
        <meta name="description" content="Generate high quality articles in one click using AI Technolgy. Watch demo video now." />
      </Helmet>

      
    <div style={{backgroundColor: 'white', width: '100%', height: '100%', display: 'flex', flexDirection: 'row'}}>
      <textarea style={{ maxWidth: '1100px', width: '100%', backgroundColor: 'white', height: '700px', 
      fontSize: '20px', marginTop: '60px', marginLeft: '60px', border: 'none', font: 'verdana', color: '#000000' 
      }} value={output} placeholder='AI Output content will appear here...' disabled />



<div style={{height: '100%', maxWidth: '400px', width: '100%', height: '780px', backgroundColor: 'white', marginLeft: 'auto',
marginRight: 0, boxShadow: '-15px 0px 17px -25px rgba(0,0,0,0.75)', padding: '22px'
}}>
  <h3 style={{textAlign: 'center', marginBottom: '10px'}}>Input for AI Content Here</h3>
 <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Topic Name/Title:</label></div>
                  <input
                    defaultValue={inputt.ttl}
                    onChange={(e) => setInputt({ ...inputt, ttl: e.target.value })} maxLength={200} style={{ width: '100%', marginTop: '0.1rem', backgroundColor: 'rgb(255, 255, 255)', color: 'black', border: 'none', filter: 'drop-shadow(0 0 0.05rem #636363)' }} className='login-inp vsmallwid' placeholder='Topic Name/Title' type="text" required />

                 <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Related Keywords Separated by comma:</label></div>
                  <textarea defaultValue={inputt.kw} onChange={(e) => setInputt({ ...inputt, kw: e.target.value })} maxLength={500} style={{ height: '3rem', width: '100%', marginTop: '0.1rem', color: 'black', border: 'none', filter: 'drop-shadow(0 0 0.05rem #636363)' }} className='login-inp vsmallwid' placeholder='Related Keywords Separated by commas' type="text" required />
                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select Writer: </label></div>
                  <select onChange={(e) => setInputt({ ...inputt, writer: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', color: '#3d3d3d', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.05rem #636363)', fontWeight: 'bold' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option selected value="Article Writer">Article Writer</option>
                    <option value="Essay Writer">Essay Writer</option>
                    <option value="Academic Writer">Academic Writer</option>
                    <option value="Blog Writer">Blog Writer</option>
                    <option value="Humor Writer">Humor Writer</option>
                    <option value="Sales Copywriter">Sales Copywriter</option>
                    <option value="Proposal Writer">Proposal Writer</option>
                    <option value="Contract Writer">Contract Writer</option>
                    <option value="Quote Writer">Quotes Request Writer</option>
                    <option value="Business Contract Writer">Business Contract Writer</option>
                    <option value="Social Media Post Writer">Social Media Post Writer</option>
                  </select>
                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select Writing Style: </label></div>
                  <select onChange={(e) => setInputt({ ...inputt, writingStyle: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', color: '#3d3d3d', fontWeight: 'bold', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.05rem #636363)' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option selected value="Formal">Formal</option>
                    <option value="Informal">Informal</option>
                    <option value="Storytelling">Storytelling</option>
                    <option value="Descriptive">Descriptive</option>
                    <option value="Convincing">Convincing</option>
                  </select>
                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select Your Audience: </label></div>
                  <select onChange={(e) => setInputt({ ...inputt, audience: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.05rem #636363)', color: '#3d3d3d', fontWeight: 'bold' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option selected value="General">General</option>
                    <option value="College Level">College Level</option>
                    <option value="peachExpert Level">Expert Level</option>
                  </select>
                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select AI Engine (Optional): <span onClick={togglePopup} style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}>Click Here for Info</span></label></div>
                  <select onChange={(e) => setInputt({ ...inputt, engine: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.05rem #636363)', color: '#3d3d3d', fontWeight: 'bold' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option value="pineapple">--- Select AI Engine (Optional) ---</option>
                    <option selected value="mango">Mango AI</option>
                    <option value="strawberry">Strawberry AI</option>
                    <option value="pineapple">Pineapple AI</option>
                    <option value="peach">Peach AI</option>
                  </select>
                  <button onClick={(e) => process(e)} style={{ width: '100%', backgroundColor: '#0074d4' }} class='button vsmallwid'><i class={loading ? "fa fa-spinner fa-spin" : "fa fa-play"}></i><b> {btntxt}</b></button>
                  {/* <Videopopup videourl={'https://www.youtube.com/embed/yUct-LiBmsw'} triggerr={<button style={{ border: '1px solid red', backgroundColor: 'white', color: 'red', width: '100%', }} class='button vsmallwid'><i className='fa fa-video-camera'></i> <b>Watch Demo Video</b></button>} /> */}
                  {
                output.length > 0 && <>
                  <input onChange={(e) => setWritemore(e.target.value)} style={{ width: '100%', marginTop: '0.7rem' }} className='login-inp vsmallwid' placeholder='Enter Sub-Topic(s) or Keyword(s)' type="text" required />
                  <button onClick={(e) => process2(e)} style={{ width: '100%' }} class='button vsmallwid'><i class={loading2 ? "fa fa-spinner fa-spin" : "fa fa-play"}></i><b> {btntxt2}</b></button>
                </>
              }






                  <div>
                  {isOpen && <Popup
          content={<>
            <h5><b>AI Writer Go Engines</b></h5>
            <p>AI Writer Go uses four different AI Engines to generate unique and high-quality content. You can experiment with different engines to get the best content out of this AI Writer.
              <br />
              You can check the features of each AI Engine below: <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Peach AI Engine:</b> One of the fastest engines which can generate simple content faster and consume a minimum number of credits. <br /><b>Peach AI Engine Consume 0.8 Credit per Word.</b>
              <br /> {'<'} -------------- {'>'} <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Pineapple AI Engine: </b> Fast yet powerful AI Engine which can generate moderately complex words and is cheaper than Strawberry and Mango AI Engines. <br /> <b>Pineapple AI Engine consumes 1 Credit per Word.</b>
              <br /> {'<'} -------------- {'>'} <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Strawberry AI Engine: </b>Generate more complex content than Peach and Pineapple AI Engines and consume fewer credits than Mango AI. Text generation is moderately faster. <br /> <b>Strawberry AI Engine consumes 1.3 Credits per Word.</b>
              <br /> {'<'} -------------- {'>'} <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Mango AI Engine: </b> Very advanced AI model can generate long and complex content but is slower and more costly than the rest of the other 3 AI Engines. <br /> <b>Mango AI Engine consumes 4.5 Credits per Word.</b>
            </p>
          </>}
          handleClose={togglePopup}
        />}
      </div>










</div>
{
  output.length > 0 && <>
  <div style={{display: 'flex', position: 'fixed', bottom: 0, backgroundColor: 'white', padding: '8px', border: '1px solid #353535'}}> 
<div style={{ width: '100%', display: 'flex', flexDirection: 'row', color: 'black', textAlign: 'center' }}><span style={{fontSize: '18px'}}>{`Words: ${output.split(' ').length - 1}`}</span><button onClick={(e) => setOutput('')} style={{ backgroundColor: '#d34747', borderRadius: '0.4rem', marginLeft: '1rem', outlineColor: 'transparent', borderColor: 'transparent', color: 'white' }}><i className='fa fa-eraser'></i> <b> Clear</b></button><button onClick={(e) => copyClipboard()} style={{ backgroundColor: 'green', borderRadius: '0.4rem', marginLeft: '1rem', outlineColor: 'transparent', borderColor: 'transparent', color: 'white' }}><i className='fa fa-eraser'></i> <b> Copy</b></button></div>
</div>
  </>
}
    </div>
    </>
  )























} else {
  return (

    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>AI Writer Go - AI Article Writer Tool</title>
        <meta name="description" content="Generate high quality articles in one click using AI Technolgy. Watch demo video now." />
      </Helmet>


      <div class="container rounded mt-10 mb-5">
        <div class="row">

          <div class="col-md-6 ">
            <div class="p-3 py-5">
              <>
                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                  <h4 style={{ marginBottom: '20px', color: 'black', textAlign: 'center' }}>Use AI Writer Go with Almaville Media</h4>
                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Topic Name/Title:</label></div>
                  <input
                    defaultValue={inputt.ttl}
                    onChange={(e) => setInputt({ ...inputt, ttl: e.target.value })} maxLength={200} style={{ width: '100%', marginTop: '0.1rem', backgroundColor: 'rgb(255, 255, 255)', color: 'black', border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)' }} className='login-inp vsmallwid' placeholder='Topic Name/Title' type="text" required />
                 
                    <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Related Keywords Separated by comma:</label></div>
                  <textarea defaultValue={inputt.kw} onChange={(e) => setInputt({ ...inputt, kw: e.target.value })} maxLength={500} style={{ height: '3rem', width: '100%', marginTop: '0.1rem', color: 'black', border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)' }} className='login-inp vsmallwid' placeholder='Related Keywords Separated by commas' type="text" required />



                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select Writer: </label></div>
                  <select onChange={(e) => setInputt({ ...inputt, writer: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', color: '#3d3d3d', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)', fontWeight: 'bold' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option selected value="Article Writer">Article Writer</option>
                    <option value="Essay Writer">Essay Writer</option>
                    <option value="Academic Writer">Academic Writer</option>
                    <option value="Blog Writer">Blog Writer</option>
                    <option value="Humor Writer">Humor Writer</option>
                    <option value="Sales Copywriter">Sales Copywriter</option>
                    <option value="Proposal Writer">Proposal Writer</option>
                    <option value="Contract Writer">Contract Writer</option>
                    <option value="Quote Writer">Quotes Request Writer</option>
                    <option value="Business Contract Writer">Business Contract Writer</option>
                    <option value="Social Media Post Writer">Social Media Post Writer</option>
                  </select>

                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select Writing Style: </label></div>
                  <select onChange={(e) => setInputt({ ...inputt, writingStyle: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', color: '#3d3d3d', fontWeight: 'bold', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option selected value="Formal">Formal</option>
                    <option value="Informal">Informal</option>
                    <option value="Storytelling">Storytelling</option>
                    <option value="Descriptive">Descriptive</option>
                    <option value="Convincing">Convincing</option>
                  </select>

                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select Your Audience: </label></div>
                  <select onChange={(e) => setInputt({ ...inputt, audience: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)', color: '#3d3d3d', fontWeight: 'bold' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option selected value="General">General</option>
                    <option value="College Level">College Level</option>
                    <option value="peachExpert Level">Expert Level</option>
                  </select>




                  {/*  
    <input style={{marginTop:'30px'}} onChange={(e)=>changesldr(e)} className='login-inp vsmallwid' type="range" min="1" max="20" defaultValue="5"/>
      No. of Unique Articles:
      <input style={{width:'100%',marginTop:'0.1rem'}} className='login-inp vsmallwid' placeholder='No of Results' maxlength='10' value={inputt.num} type="number" required /> */}

                  <div style={{ width: '100%', marginTop: '0.4rem' }}><label style={{ textAlign: 'left', alignSelf: 'left', color: '#000000', fontWeight: 'bold' }} class="labels">Select AI Engine (Optional): <span onClick={togglePopup} style={{ color: 'black', cursor: 'pointer', textDecoration: 'underline', fontWeight: 'bold' }}>Click Here for Info</span></label></div>
                  <select onChange={(e) => setInputt({ ...inputt, engine: e.target.value })} style={{ width: '100%', marginTop: '0.1rem', backgroundColor: 'white', border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)', color: '#3d3d3d', fontWeight: 'bold' }}
                    // onChange={(e) => onCatagoryChange(e)} 
                    className='login-inp vsmallwid'>
                    <option value="pineapple">--- Select AI Engine (Optional) ---</option>
                    <option selected value="mango">Mango AI</option>
                    <option value="strawberry">Strawberry AI</option>
                    <option value="pineapple">Pineapple AI</option>
                    <option value="peach">Peach AI</option>
                  </select>


                  <button onClick={(e) => process(e)} style={{ width: '100%', backgroundColor: '#0074d4' }} class='button vsmallwid'><i class={loading ? "fa fa-spinner fa-spin" : "fa fa-play"}></i><b> {btntxt}</b></button>
                  {/* <Videopopup videourl={'https://www.youtube.com/embed/yUct-LiBmsw'} triggerr={<button style={{ border: '1px solid red', backgroundColor: 'white', color: 'red', width: '100%', }} class='button vsmallwid'><i className='fa fa-video-camera'></i> <b>Watch Demo Video</b></button>} /> */}
                  <Link style={{ width: '100%' }} to='../' >
                  </Link>
                </div>
              </>
            </div>

          </div>
          <div id='output-window' class="col-md-6">
            <div class="p-3 py-5">
              <h2 style={{ textAlign: 'center', color: 'black' }}>AI Content Output</h2>

              <div class="mt-5 col-md-12">

                <textarea id='resultspe' value={output} style={{ width: '100%', height: 500, border: 'none', filter: 'drop-shadow(0 0 0.3rem #d8d8d8)', color: 'black' }} className='login-inp vsmallwid lanticlass' placeholder='AI generated text will be here' type="text" />
              </div>
              <div style={{ width: '100%', display: 'flex', flexDirection: 'row', color: 'black' }}><span>{`Words: ${output.split(' ').length - 1}`}</span><button onClick={(e) => setOutput('')} style={{ backgroundColor: '#d34747', borderRadius: '0.4rem', marginLeft: '1rem', outlineColor: 'transparent', borderColor: 'transparent', color: 'white' }}><i className='fa fa-eraser'></i> <b> Clear</b></button></div>
              {
                output.length > 0 && <>
                  <input onChange={(e) => setWritemore(e.target.value)} style={{ width: '100%', marginTop: '0.7rem' }} className='login-inp vsmallwid' placeholder='Enter Sub-Topic(s) or Keyword(s)' type="text" required />
                  <button onClick={(e) => process2(e)} style={{ width: '100%' }} class='button vsmallwid'><i class={loading2 ? "fa fa-spinner fa-spin" : "fa fa-play"}></i><b> {btntxt2}</b></button>
                </>
              }

              <hr />


            </div>
          </div>
        </div>
      </div>








      <div>
        {isOpen && <Popup
          content={<>
            <h5><b>AI Writer Go Engines</b></h5>
            <p>AI Writer Go uses four different AI Engines to generate unique and high-quality content. You can experiment with different engines to get the best content out of this AI Writer.
              <br />
              You can check the features of each AI Engine below: <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Peach AI Engine:</b> One of the fastest engines which can generate simple content faster and consume a minimum number of credits. <br /><b>Peach AI Engine Consume 0.8 Credit per Word.</b>
              <br /> {'<'} -------------- {'>'} <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Pineapple AI Engine: </b> Fast yet powerful AI Engine which can generate moderately complex words and is cheaper than Strawberry and Mango AI Engines. <br /> <b>Pineapple AI Engine consumes 1 Credit per Word.</b>
              <br /> {'<'} -------------- {'>'} <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Strawberry AI Engine: </b>Generate more complex content than Peach and Pineapple AI Engines and consume fewer credits than Mango AI. Text generation is moderately faster. <br /> <b>Strawberry AI Engine consumes 1.3 Credits per Word.</b>
              <br /> {'<'} -------------- {'>'} <br />
              <b style={{ color: '#0625a0', textDecoration: 'underline' }}>Mango AI Engine: </b> Very advanced AI model can generate long and complex content but is slower and more costly than the rest of the other 3 AI Engines. <br /> <b>Mango AI Engine consumes 4.5 Credits per Word.</b>
            </p>
          </>}
          handleClose={togglePopup}
        />}
      </div>



    </>
  )
}
  
}

export default Articlewriter